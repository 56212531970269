@import 'src/assets/common.module';

.reference-document-modal-wrapper.MuiModal-root {
  z-index: $z-index-mui-snackbar;
  .metadata-form {
    margin: 0;
  }
}

.MuiPopover-root.MuiMenu-root {
  z-index: $z-index-mui-snackbar;

  .MuiList-root.MuiMenu-list {
    overflow-y: auto;
  }
}

.reference-document-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}
