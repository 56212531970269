@import 'src/assets/common.module';

.document-info-header {
  margin: 10px 10px 0 30px;
  position: relative;
  & img {
    position: absolute;
    width: 28px;
    height: 32px;
  }

  .clause-status-icon {
    float: right;
    margin: -2px 25px 0 5px;
  }

  & .file-name-text {
    display: block;
    font-size: 1.1rem;
    letter-spacing: 0.013em;
  }

  & .label-wrapper {
    display: flex;
    padding: 0 0 15px 50px;
    height: 24px;
    box-sizing: initial;
    gap: 10px;
    align-items: center;
  }

  & small {
    display: block;
    font-size: 10px;
    color: gray;
    height: 15px;
  }
  & .MuiChip-root {
    height: 18px !important;
  }
  & .MuiChip-label {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  & .provider-chip {
    color: $mid-gray;
  }
}
