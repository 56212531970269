@import 'src/assets/common.module';

.documents-list-menu {
  & .MuiPaper-root {
    width: 260px;
    border-radius: 12px;
    border: 1px solid $light-gray;
    box-shadow: 0 2px 12px 0 $gray;
  }

  & .MuiList-root {
    padding: 0 5px;
  }

  & .MuiMenuItem-root {
    color: $dark-gray-text-color;
    border-radius: 8px;
    margin: 5px 0;
    padding: 9px 12px;
    font-size: 14px;

    & .MuiSvgIcon-root {
      font-size: 16px;
      margin: -2px 6px 0 0;
      opacity: 0.6;
    }
  }

  & &-delete {
    color: $light-red;
  }

  &__error-icon.MuiSvgIcon-root {
    color: $light-red;
    width: 16px;
    height: 16px;
    margin: 11px 12px 0 0;
    float: right;
  }
}
