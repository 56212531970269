@import 'src/assets/common.module';

button.button-with-icon {
  color: $mid-gray;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: none;
  height: 34px;
  border: 1px solid $light-gray;
  border-radius: 12px;
  margin-left: 16px;

  &:hover {
    border: 1px solid $light-gray;
  }

  &.red {
    &:not([disabled]) {
      background-color: $dark-red;
      color: $white;
    }
  }

  //TODO
  //it solve issue with not fully filling button in grid but only there :)
  //& .MuiTouchRipple-root{
  //  border-radius: 11px;
  //  height: calc(100% + 1px);
  //}
}
