@import 'src/assets/common.module';

.ai-comparison-pending-modal-wrapper.MuiModal-root {
  z-index: $z-index-mui-snackbar;
}

.ai-comparison-pending-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  text-align: center;

  .spinner {
    margin: 10px 0;
  }

  .title {
    margin: 0;
  }

  .location-path {
    font-weight: 600;
  }
}
