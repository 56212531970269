$white: #ffffff;
$body-background-color: $white;

$dark-red: #790031;
$mid-red: #d50e47;
$mid-red-2: #a80047;
$light-red: #da3a57;
$lighter-red: #fde4e7;
$removed-change-lighter-red: #ffc9cb;
$top-light-red: #fef3f4;
$counter-lighter-red: #f6dfe8;
$error-row-bg: #fef9f9;
$error-row-border: #f13d52;
$eds-primary-200: #f4e2e4;
$eds-primary-800: #8e0038;
$primary-dark: #71022e;

$orange: #fc7c5f;
$light-orange: #e87a16;
$lighter-orange: #fef5ed;

$clause-highlight-current: #f6cb00;
$clause-highlight-opacity: #f6cb0052;
$clause-highlight: #fdefc1;

$ergo-ice-blue-dark-3: #0f94a7;

$lightest-gray: #fafafa;
$lighter-gray: #e8e8e8;
$light-gray: #d9d9d9;
$light-gray-2: #f2f2f2;
$dark-gray-text-color: #333333;
$mid-gray: #737373;
$mid-gray-2: #969696;
$gray: #f2f2f2;
$gray-2: #d3d3d3;

$gray-mui-disabled-button-border: #0000001f;
$gray-mui-disabled-button-label: #00000042;
$gray-mui-disabled-button-background: #0000001f;

$green: #4da36a;
$light-green: #009284;
$lighter-green: #edfbf0;
$added-change-lighter-green: #ceede7;

$font-family: 'FS Me Web';
$font-family-fedra: 'Fedra Serif';

$z-index-column-header: 1;
$z-index-mui-modal: 1300;
$z-index-mui-snackbar: 1400;
$z-index-mui-notification: 1500;

:export {
  fontFamily: $font-family;
  darkRed: $dark-red;
  midGray: $mid-gray;
  darkGrayTextColor: $dark-gray-text-color;
  white: $white;
  zIndexMuiModal: $z-index-mui-modal;
  zIndexMuiSnackbar: $z-index-mui-snackbar;
}

@mixin sticky-header {
  & .MuiDataGrid-columnHeaders {
    position: sticky;
    background-color: $white;
    z-index: $z-index-column-header;
  }

  & .MuiDataGrid-virtualScroller {
    margin-top: 0 !important;
  }

  & .MuiDataGrid-main {
    overflow: unset;
  }
}

li.MuiMenuItem-root[hidden] {
  display: none;
}
